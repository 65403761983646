import React, { useEffect, useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Card from 'react-bootstrap/Card'
import exito from '../exito.png';
import '../App.css';

function Finalizado(props) {



  return (
    <Container>
      <Row className="p-3 justify-content-md-center">
        <Col lg="6">
          <Card>
            <Card.Body>
              <Row>
                <Col className="text-center mt-3 mb-5">
                  <img className="logo" src={props.logo} />
                </Col>
              </Row>
              <Row>
                <Col className="text-center mt-3 mb-5">
                  <img width="100" src={exito} />
                </Col>
              </Row>
              <Row>
                <Col className="text-center mt-1 mb-5">
                  Gracias por ayudarnos a mejorar tu experiencia de entrega
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  )

}
export default Finalizado;